import { IAuthCaptchaModel, IAuthUserModel } from "../models/user"
import { http } from "../utils"

class _UserServices {
  /**
   * 登录
   * @param p
   * @returns
   */
  async login(p: any): Promise<IAuthUserModel> {
    return await http.post('/admin/v1/auth/login', p)
  }

  /**
   * 获取图形验证码
   * @returns
   */
  async getCaptcha(): Promise<IAuthCaptchaModel> {
    return await http.post('/admin/v1/auth/captcha')
  }

  /**
   * 上传
   * @param key icon | banner
   * @param file 上传的文件
   * @returns
   */
  async upload(key: string, file: File): Promise<string> {
    const fd = new FormData()
    fd.append('file', file)
    return await http.post(`/admin/v1/upload/${key}`, fd)
  }
}

export const UserServices = new _UserServices()