import { makeAutoObservable } from "mobx"
import { NewsServices, ProductServices } from "../services"
import { PageStore } from "./page"
import UserStore from "./user"

export * from './page'

interface IModelDataMap {
  key: string
  value: string
}

class ModelDataMap<T> {
  store: PageStore<T>

  private key: string

  private value: string

  private needForceRefresh = false

  constructor(store: PageStore<T>, {
    key, value
  }: IModelDataMap) {
    this.store = store
    this.key = key
    this.value = value
    makeAutoObservable(this)
  }

  async refresh(): Promise<void> {
    if(this.needForceRefresh || this.store.list.length === 0) {
      this.needForceRefresh = false
      await this.store.refresh()
    }
  }

  setForceRefresh() {
    this.needForceRefresh = true
  }

  get list(): T[] {
    return this.store.list
  }

  get toJSON(): any {
    return this.store.list?.reduce((res: any, item: any) => ({
      ...res,
      [item[this.key]]: item[this.value]
    }), {})
  }
}

export class Store {
  private static _instance: Store

  constructor() {
    if(!Store._instance) {
      Store._instance = this
    }

    return Store._instance
  }

  user: UserStore = new UserStore()

  productCategoryMap = new ModelDataMap(
    new PageStore({
      query: ProductServices.getCategoryPage,
      size: 100
    }),
    { key: 'id', value: 'name' }
  )

  newsCategoryMap = new ModelDataMap(
    new PageStore({
      query: NewsServices.getCategoryPage,
      size: 100
    }),
    { key: 'id', value: 'name' }
  )
}