import { ConfigProvider, Spin } from "antd"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import './index.less'
import zhCN from 'antd/lib/locale/zh_CN';
import loadable from "@loadable/component"
import { StoreProvider } from "../components/Hooks/StoreProvider"
import 'moment/locale/zh-cn';
import moment from "moment";
import { AdminLayout } from "../components/AdminLayout";

moment.locale('zh-cn');

function defaultFallbackLoadable(cb: () => Promise<any>) {
  return loadable(cb, {
    fallback: <Spin />
  })
}

const Login = defaultFallbackLoadable(() => import('../pages/Login'))
const Product = defaultFallbackLoadable(() => import('../pages/Product'))
const ProductCategory = defaultFallbackLoadable(() => import('../pages/ProductCategory'))
const News = defaultFallbackLoadable(() => import('../pages/News'))
const NewsCategory = defaultFallbackLoadable(() => import('../pages/NewsCategory'))
const Banner = defaultFallbackLoadable(() => import('../pages/Banner'))
const Feedback = defaultFallbackLoadable(() => import('../pages/Feedback'))

function App() {
  return (
    <ConfigProvider locale={zhCN}>
      <StoreProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={(
              <AdminLayout>
                <Routes>
                  <Route path="/" element={<Navigate to="/product" replace={true} />} />
                  <Route path="/product" element={<Product />} />
                  <Route path="/productCategory" element={<ProductCategory />} />
                  <Route path="/news" element={<News />} />
                  <Route path="/newsCategory" element={<NewsCategory />} />
                  <Route path="/banner" element={<Banner />} />
                  <Route path="/feedback" element={<Feedback />} />
                </Routes>
              </AdminLayout>
            )} />
          </Routes>
        </BrowserRouter>
      </StoreProvider>
    </ConfigProvider>
  )
}

export default App
