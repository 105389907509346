import { IPageModel, IPageParams, IProductCategoryPageModel, IProductPageModel } from "../models";
import { http } from "../utils";

class _ProductServices {
  /**
   * 分类分页
   * @param p
   * @returns
   */
  async getCategoryPage(p: IPageParams): Promise<IPageModel<IProductCategoryPageModel>> {
    return await http.get('/admin/v1/product/category/page', p)
  }

  /**
   * 创建分类
   * @param p { "name": string, "icon": string }
   * @returns
   */
  async createCategory(p: any) {
    return await http.post('/admin/v1/product/category', p)
  }

  /**
   * 更新分类
   * @param id 分类 ID
   * @param p { "name": string, "icon": string }
   * @returns
   */
  async updateCategoryById(id: number, p: any) {
    return await http.put(`/admin/v1/product/category/${id}`, p)
  }

  /**
   * 删除分类
   * @param id 分类 ID
   * @returns
   */
  async removeCategoryById(id: number) {
    return await http.delete(`/admin/v1/product/category/${id}`)
  }

  /**
   * 产品分页
   * @param p
   * @returns
   */
   async getPage(p: IPageParams): Promise<IPageModel<IProductPageModel>> {
    return await http.post('/admin/v1/product/page', p)
  }

  /**
   * 创建产品
   * @param p { "title":"title", "subtitle": "subtitle", "description": "description", "content": "11", "category_id": 1, "images": string[] }
   * @returns
   */
  async create(p: any) {
    return await http.post('/admin/v1/product', p)
  }

  /**
   * 更新分类
   * @param id 产品 ID
   * @param p { "title":"title", "subtitle": "subtitle", "description": "description", "content": "11", "category_id": 1, "images": string[] }
   * @returns
   */
  async updateById(id: number, p: any) {
    return await http.put(`/admin/v1/product/${id}`, p)
  }

  /**
   * 删除产品
   * @param id 产品 ID
   * @returns
   */
  async removeById(id: number) {
    return await http.delete(`/admin/v1/product/${id}`)
  }
}

export const ProductServices = new _ProductServices()