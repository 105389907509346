import { makeAutoObservable } from "mobx"
import { IAuthUserModel } from "../models/user"
import { UserServices } from "../services"
import { tokenStorage, userStorage } from "../utils/Storage"

export default class UserStore {
  private static _instance: UserStore

  _info: IAuthUserModel | undefined = undefined

  constructor() {
    if(!UserStore._instance) {
      UserStore._instance = this
      makeAutoObservable(this)
    }

    return UserStore._instance
  }

  get info(): IAuthUserModel | undefined {
    if(this._info) return this._info
    const info = userStorage.get()
    if(info) {
      this._info = info
      return info
    }
  }

  get isLogin(): boolean {
    return !!this.info
  }

  async login(p: any) {
    const info = await UserServices.login(p)
    this._info = info
    userStorage.set(this._info)
    tokenStorage.set(info.token)
  }

  logout() {
    this._info = undefined
    tokenStorage.remove()
    userStorage.remove()
    // UserServices.logout().catch((_) => {})
  }
}