import { tokenStorage } from "./Storage";
import { isArray } from "underscore";
import { loginStatusObserver } from "./observer";

interface IHttpRequestOption2 {
  headers?: {
    [key: string]: string
  }
}

interface IHttpRequestOption extends IHttpRequestOption2 {
  path: string;
  method?: string;
  params?: any;
  data?: any;
}

interface IHttpResponse {
  data: any
  code: number
  msg?: string
  errors?: string[]
}

export class NotFoundError {
  message: string

  constructor(message: string) {
    this.message = message
  }
}

class HttpRequest {
  get baseUrl() {
    return process.env.REACT_APP_API_HOST
  }

  get(url: string, params?: any) {
    return this.request({
      path: url,
      params: params,
      method: 'GET'
    })
  }

  getList(url: string, params?: any) {
    return this.get(url, params)
      .then((res) => isArray(res) ? res : [])
  }

  post(url: string, data?: any, option?: IHttpRequestOption2) {
    return this.request({
      path: url,
      data,
      method: 'POST',
      ...option,
    })
  }

  patch(url: string, data?: any) {
    return this.request({
      path: url,
      data,
      method: 'PATCH'
    })
  }

  delete(url: string, data?: any) {
    return this.request({
      path: url,
      data,
      method: 'DELETE'
    })
  }

  put(url: string, data?: any) {
    return this.request({
      path: url,
      data,
      method: 'PUT'
    })
  }

  withToken(data: any): any {
    if(!data) data = {}
    // const token = tokenStorage.get()
    // if(token) {
    //   data = {
    //     ...data,
    //     token
    //   }
    // }
    return data
  }

  private async request(option: IHttpRequestOption) {
    const fetchOption: any = {
      method: option.method,
      headers: {
        ...(option.headers || {})
      }
    };
    let url = this.baseUrl + option.path;

    // token 导入
    const token = tokenStorage.get()
    if(token) {
      fetchOption.headers['Authorization'] = `Bearer ${token}`
    }

    if (option.method !== 'GET' && option.data) {
      if(option.data instanceof FormData) {
        fetchOption.body = option.data
      } else {
        fetchOption.body = JSON.stringify(option.data)
        fetchOption.headers['Content-Type'] = 'application/json';
      }
    }
    if (option.params) {
      url += `?${this.serializationParams(option.params)}`;
    }

    const res = await fetch(url, fetchOption);
    const data: IHttpResponse = await res.json();
    if (data.code !== 200) {
      if(data.code === 401) {
        loginStatusObserver.emit()
      } else if(data.code === 500) {
        throw new Error('server exception')
      } else if(data.code === 404) {
        throw new NotFoundError('not found')
      } else {
        throw new Error(data.msg);
      }
    }
    return data.data;
  }

  private serializationParams(params: any) {
    if (typeof (params) === 'string') {
      return params;
    }
    const arr: any[] = [];
    for (const i in params) {
      const item = params[i]
      if(item !== undefined && item !== null) {
        if (typeof item === 'string') {
          arr.push(encodeURIComponent(i) + '=' + encodeURIComponent(params[i]));
        }
        else {
          arr.push(encodeURIComponent(i) + '=' + encodeURIComponent(JSON.stringify(params[i])));
        }
      }
    }
    return arr.join('&');
  }
}

export default new HttpRequest();