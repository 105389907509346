import { INewsCategoryPageModel, INewsPageModel, IPageModel, IPageParams } from "../models";
import { http } from "../utils";

class _NewsServices {
  /**
   * 分类分页
   * @param p
   * @returns
   */
  async getCategoryPage(p: IPageParams): Promise<IPageModel<INewsCategoryPageModel>> {
    return await http.get('/admin/v1/news/category/page', p)
  }

  /**
   * 创建分类
   * @param p { "name": string, "icon": string }
   * @returns
   */
  async createCategory(p: any) {
    return await http.post('/admin/v1/news/category', p)
  }

  /**
   * 更新分类
   * @param id 分类 ID
   * @param p { "name": string, "icon": string }
   * @returns
   */
  async updateCategoryById(id: number, p: any) {
    return await http.put(`/admin/v1/news/category/${id}`, p)
  }

  /**
   * 删除分类
   * @param id 分类 ID
   * @returns
   */
  async removeCategoryById(id: number) {
    return await http.delete(`/admin/v1/news/category/${id}`)
  }

  /**
   * 产品分页
   * @param p
   * @returns
   */
   async getPage(p: IPageParams): Promise<IPageModel<INewsPageModel>> {
    return await http.get('/admin/v1/news/page', p)
  }

  /**
   * 创建新闻
   * @param p
   * @returns
   */
  async create(p: any) {
    return await http.post('/admin/v1/news', p)
  }

  /**
   * 更新新闻
   * @param id 新闻 ID
   * @param p
   * @returns
   */
  async updateById(id: number, p: any) {
    return await http.put(`/admin/v1/news/${id}`, p)
  }

  /**
   * 删除新闻
   * @param id 新闻 ID
   * @returns
   */
  async removeById(id: number) {
    return await http.delete(`/admin/v1/news/${id}`)
  }
}

export const NewsServices = new _NewsServices()