import { Layout, Menu } from "antd";
import { FC, useEffect } from "react";
import { Observer, useLocalObservable } from 'mobx-react-lite'
import { useLocation, useNavigate } from "react-router-dom";
import css from './index.module.scss'
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { useStore } from "../Hooks/StoreProvider";
import { loginStatusObserver } from "../../utils";

// interface ISiderNavOption {
//   title: string
//   path: string
//   children?: ISiderNavOption[]
// }

const _siderNavList: ItemType[] = [
  {
    label: '产品列表',
    key: '/product'
  }, {
    label: '产品分类',
    key: '/productCategory'
  }, {
    label: '新闻列表',
    key: '/news',
  }, {
    label: '新闻分类',
    key: '/newsCategory',
  }, {
    label: '幻灯片列表',
    key: '/banner',
  }, {
    label: '用户反馈列表',
    key: '/feedback'
  }
]

export const AdminLayout: FC<{
  children: React.ReactNode
}> = ({
  children
}) => {
  const location = useLocation()
  const store = useStore()
  const navigate = useNavigate()
  const state = useLocalObservable(() => ({
    collapsed: false,
    setCollapsed(collapsed: boolean) {
      state.collapsed = collapsed
    },
  }))

  const handleLogout = () => {
    store.user.logout()
    navigate('/login')
  }

  useEffect(() => {
    loginStatusObserver.on(handleLogout)

    return () => {
      loginStatusObserver.remove(handleLogout)
    }
  }, [])

  return (
    <Observer>
      {() => (
        <Layout style={{ minHeight: '100vh' }}>
          <Layout.Sider
            collapsible
            collapsed={state.collapsed}
            onCollapse={state.setCollapsed}
          >
            <div className={css.logo} >百 智 德</div>
            <Menu
              theme="dark"
              selectedKeys={[location.pathname]}
              mode="inline"
              onSelect={({ key }) => {
                navigate(key)
              }}
              items={_siderNavList}
            />
          </Layout.Sider>
          <Layout className="site-layout">
            <Layout.Header className="site-layout-background" style={{ padding: 0 }} />
            <Layout.Content style={{ margin: 16 }}>
              <div className={css.mainContent}>
                {children}
              </div>
            </Layout.Content>
            <Layout.Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Layout.Footer>
          </Layout>
        </Layout>
      )}
    </Observer>
  )
}